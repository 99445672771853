import React, {useEffect} from 'react'
import './pricePage.css'
import {priceData} from "../../Data"
import {Navigation, Pagination} from "swiper"
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import "swiper/css/zoom"
import Aos from "aos"
import 'aos/dist/aos.css'

const PricePage = () => {
    useEffect(() => {
        Aos.init({duration:1500})
    }, [])

    return (
        <>
            <section id='price_page'>
                <Swiper data-aos='zoom-in' data-aos-duration='2000'
                    style={{
                        "--swiper-navigation-color": "var(--color-bg-footer)",
                        "--swiper-pagination-color": "var(--color-bg-footer)",
                    }}
                    slidesPerView={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="priceSwiper"
                >
                    {
                        priceData.map((price, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img className='price_img' src={price.img} alt='order a sketch'/>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </section>
        </>
    )
}

export default PricePage