import React, {useEffect} from 'react';
import './portfolio.css'
// import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { sketchProjectData } from '../../Data'

import Aos from "aos";
import 'aos/dist/aos.css'

const Portfolio = ({observer, sectionRef}) => {
    useEffect(() => {
        Aos.init({duration:1500})
    }, [])

    useEffect(() => {
        if (observer) {
            observer.observe(sectionRef.current)
        }
    }, [sectionRef, observer])

    return (
        <section id='portfolio' className='portfolio' ref={sectionRef}>
            <div data-aos='fade-up' data-aos-duration='2000' className='portfolio_header'>
                <h2>My Recent Projects</h2>
                <h1>Portfolio</h1>
            </div>
            <div data-aos='zoom-in' data-aos-duration='2000' className='container portfolio_container'>
                {/*<div className='portfolio_item' style={{marginBottom: '2rem'}}>*/}
                    {/*<Swiper modules={[Autoplay, Navigation, Pagination]}*/}
                    {/*        spaceBetween={40}*/}
                    {/*        slidesPerView={1}*/}
                    {/*        pagination={{ clickable: true }}*/}
                    {/*        style={{*/}
                    {/*            "--swiper-navigation-color": "#fff"*/}
                    {/*        }}*/}
                    {/*        autoplay={{*/}
                    {/*            delay: 6000,*/}
                    {/*            disableOnInteraction: false,*/}
                    {/*            // pauseOnMouseEnter: true,*/}
                    {/*        }}*/}
                    {/*>*/}
                    {/*    {*/}
                    {/*        designProjectData.map((project, index) => {*/}
                    {/*            return (*/}
                    {/*                <SwiperSlide key={index}>*/}
                    {/*                    <div className='project_design_img'>*/}
                    {/*                        <a href={project.designImg} target='_blank' rel="noreferrer">*/}
                    {/*                            <img src={project.designImg} alt='Design'/>*/}
                    {/*                        </a>*/}
                    {/*                    </div>*/}
                    {/*                    <small className='design_description'>{project.projectName}</small>*/}
                    {/*                </SwiperSlide>*/}
                    {/*            )*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</Swiper>*/}
                {/*</div>*/}
                <div className='portfolio_item'>
                    <Swiper modules={[Autoplay, Navigation, Pagination]}
                            spaceBetween={40}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            navigation={true}
                            autoplay={{
                                delay: 6000,
                                disableOnInteraction: false,
                                // pauseOnMouseEnter: true,
                            }}
                            style={{
                                "--swiper-navigation-color": "var(--color-bg)",
                                "--swiper-pagination-color": "var(--color-bg)",
                            }}
                    >
                        {
                            sketchProjectData.map((project, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className='project_design_img'>
                                            <a href={project.designImg} target='_blank' rel="noreferrer">
                                                <img src={project.designImg} alt='Sketch'/>
                                            </a>
                                        </div>
                                        <small className='design_description'>{project.projectName}</small>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
