import React, {useEffect} from 'react';
import './about.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

const About = ({observer, sectionRef}) => {

    useEffect(() => {
        Aos.init({duration:1500})
    }, [])

    useEffect(() => {
        if (observer) {
            observer.observe(sectionRef.current)
        }
    }, [sectionRef, observer])

    return (
        <section id='about'>
            <div data-aos='fade-up' data-aos-duration='2000' className='about_header'>
                <h2>Get To Know</h2>
                <h1>About me</h1>
            </div>

            <div className='container about_container'>
                <div data-aos='fade-right' data-aos-duration='2000' className='about_me'>
                    {/*<div className='about_me_image'>*/}
                    {/*    <img src={MyPhoto} alt='me'/>*/}
                    {/*</div>*/}
                </div>
                <div data-aos='zoom-in' data-aos-duration='2000' className='about_content'>
                    {/*<div className='about_cards'>*/}
                    {/*    <article className="about_card">*/}
                    {/*        <GiStairsGoal className='about_icon'/>*/}
                    {/*        <h5>Experience</h5>*/}
                    {/*        <p className='small'>1+ Years Working And Study</p>*/}
                    {/*    </article>*/}
                    {/*    <article className="about_card">*/}
                    {/*        <FiUsers className='about_icon'/>*/}
                    {/*        <h5>Clients</h5>*/}
                    {/*        <p className='small'>2+ Worldwide</p>*/}
                    {/*    </article>*/}
                    {/*    <article className="about_card">*/}
                    {/*        <AiOutlineFundProjectionScreen className='about_icon'/>*/}
                    {/*        <h5>Projects</h5>*/}
                    {/*        <p className='small'>5+ Completed</p>*/}
                    {/*    </article>*/}
                    {/*</div>*/}
                    <p className='about_me_text'>
                        I'm Yana, a passionate architectural artist with an international background.<br/>
                        My journey as an artist and designer has led me to specialize in creating exclusive sketches that add a touch of creativity and vividness to your projects.<br/>
                        My sketches are created for:
                        <span className='about_me_list'>Interior designers</span>
                        <span className='about_me_list'>Architects</span>
                        <span className='about_me_list'>Landscape designers</span>
                        <span className='about_me_list'>Real estate agencies</span>
                        I firmly believe that a sketch is not just a representation; it's a fast, creative, and cost-effective way to breathe life into your vision.<br/>
                        My goal is to transform ideas into visually stunning realities.<br/><br/>
                        <b id='about_me' ref={sectionRef}>Professional education:</b><br/>
                        I have a degree in Interior Design from the St. Petersburg International Design School, and it's from there that my love for design took flight.<br/><br/>
                        <b>Technical skills:</b><br/>
                        I have experience with a variety of tools, including AutoCAD, ArchiCAD, 3DsMax, Adobe Photoshop, Procreate. Using these tools, I bring your ideas to life.<br/><br/>
                        As an artist and designer, I'm committed to continuous professional development. Each day is an opportunity to develop my skills and stay at the forefront of design trends.
                        I look forward to the opportunity to collaborate on your next project and bring your vision to life with my sketches. Let's turn your ideas into stunning visuals!<br/>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;
