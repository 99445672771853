import React, {useEffect, useRef} from 'react'
import './header.css'
import HeaderSocials from "./HeaderSocials"
import Aos from "aos"
import 'aos/dist/aos.css'
import MyPhoto from "../../images/image-03-10-23-11-32.jpg"

const Header = ({observer, setActiveNav}) => {
    const sectionRef = useRef(null)

    useEffect(() => {
        Aos.init({duration:1500})
    }, [])

    useEffect(() => {
        if (observer) {
            observer.observe(sectionRef.current)
        }
    }, [sectionRef, observer])

    return (
        <header>
            <div className="container header_container">
                <div id='header' className="header_text" ref={sectionRef}>
                    <h2 className='letter_space'>Hello I'm</h2>
                    <h1>Yana Trofimova</h1>
                    <h3 className="letter_space">Interior Designer and Architectural artist</h3>
                </div>
                <div data-aos='zoom-in' data-aos-duration='2000' className='me'>
                    <img src={MyPhoto} alt='Yana Trofimova' className='header_image'/>
                </div>
                {/*<HeaderButtons/>*/}
                {/*<HeaderSocials/>*/}
                {/*<a href='#contact' onClick={() => setActiveNav('#contact')} className='scroll_down'>Scroll Down</a>*/}
            </div>
        </header>
    );
};

export default Header;
