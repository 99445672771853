import React, {useEffect, useState} from "react"
import './blogPage.css'

import {useParams} from "react-router-dom"
import {Link} from "react-router-dom"
import {blogData} from "../../Data"
import {RxDoubleArrowLeft, RxDoubleArrowRight} from "react-icons/rx"
import Aos from "aos"
import 'aos/dist/aos.css'

const BlogPage = () => {
    const [postData, setPostData] = useState({prevPath: '/', nextPath: '/'})

    let { post } = useParams()

    useEffect(() => {
        Aos.init({duration:1500})
        setPostDataBasedOnPath()
    }, [post])

    const setPostDataBasedOnPath = () => {
        if (post === 'aboutSketch') {
            setPostData(({...blogData[0]}))
        }
        if (post === 'sketchTypes') {
            setPostData(({...blogData[1]}))
        }
        if (post === 'sketchForDesigners') {
            setPostData(({...blogData[2]}))
        }
        if (post === 'editsTypes') {
            setPostData(({...blogData[3]}))
        }
        if (post === 'japandiStyle') {
            setPostData(({...blogData[4]}))
        }
        if (post === 'procreateTips') {
            setPostData(({...blogData[5]}))
        }
        if (post === 'architecturalSketch') {
            setPostData(({...blogData[6]}))
        }
        if (post === 'popularQuestion') {
            setPostData(({...blogData[7]}))
        }
    }

    return (
        <>
            <section id='blogPage'>
                <h2>{postData.title}</h2>
                <div className='container'>
                    <div className='blogpage_header'>
                        <Link to={postData.prevPath}
                              className={`btn-blog ${postData.prevPath !== '' ? 'active' : 'disabled'}`}>
                            <RxDoubleArrowLeft className='blogpage_icon'/>&nbsp;Previous
                        </Link>
                        <Link to={postData.nextPath}
                              className={`btn-blog ${postData.nextPath !== '' ? 'active' : 'disabled'}`}>Next&nbsp;
                            <RxDoubleArrowRight className='blogpage_icon'/>
                        </Link>
                    </div>
                    <div data-aos='zoom-in' data-aos-duration='2000' className='blogpage_container'>
                        <div className='blogpage_image'>
                            <img src={postData.img} alt='sketch'/>
                        </div>
                        <div className='blogpage_content'>
                            <p className='blogpage_text'>
                                {postData.description}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogPage