import React, {useEffect} from 'react'
import './services.css'
import { GoPrimitiveDot } from 'react-icons/go'
import { designServiceData, sketchServiceData } from "../../Data"
import Aos from "aos"
import 'aos/dist/aos.css'

const Services = ({observer, sectionRef}) => {
    useEffect(() => {
        Aos.init({duration:1500})
    }, [])

    useEffect(() => {
        if (observer) {
            observer.observe(sectionRef.current)
        }
    }, [sectionRef, observer])

    return (
        <section id='services' ref={sectionRef}>
            <div data-aos='fade-up' data-aos-duration='2000' className='services_header'>
                <h2>What I Offer</h2>
                <h1>Price list</h1>
            </div>
            <div data-aos='zoom-in' data-aos-duration='2000' className='container services_container'>
                {/*<article className='service'>*/}
                {/*    <div className='service_head'>*/}
                {/*        <h3>Interior Design</h3>*/}
                {/*    </div>*/}
                {/*    <ul className='service_list'>*/}
                {/*        {*/}
                {/*            designServiceData.map((service, index) => {*/}
                {/*                return (*/}
                {/*                    <li key={index}>*/}
                {/*                        <GoPrimitiveDot className='service_list_icon'/>*/}
                {/*                        <div className='service_text'>*/}
                {/*                            <span>{service.name}</span>*/}
                {/*                            <span className='dotted'/>*/}
                {/*                            <span className='price'>{service.price}</span>*/}
                {/*                        </div>*/}
                {/*                    </li>*/}
                {/*                )*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </ul>*/}
                {/*</article>*/}
                <article className='service'>
                    <div className='service_head'>
                        <h3>Sketching</h3>
                    </div>
                    <ul className='service_list'>
                        {
                            sketchServiceData.map((service, index) => {
                                return (
                                    <li key={index} >
                                        <GoPrimitiveDot className='service_list_icon'/>
                                        <div className='service_text'>
                                            <span className='service_name'>{service.name}</span>
                                            <span className='dotted'/>
                                            <span className='price'>{service.price}</span>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </article>
            </div>
        </section>
    );
};

export default Services;
