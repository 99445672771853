import React, {useEffect, useState} from 'react'
import './workProcess.css'
import {stepsData} from "../../Data"
import Step from "./Step"
import Aos from "aos"
import 'aos/dist/aos.css'

const WorkProcess = ({observer, sectionRef}) => {
    const [currentStep, setCurrentStep] = useState({...stepsData[0]})
    const [activeStep, setActiveStep] = useState(0)

    useEffect(() => {
        Aos.init({duration:1500})
    }, [])

    useEffect(() => {
        if (observer) {
            observer.observe(sectionRef.current)
        }
    }, [sectionRef, observer])

    return (
        <section id='steps' ref={sectionRef}>
            <div data-aos='fade-up' data-aos-duration='2000' className='steps_header'>
                <h2>Work process</h2>
                <h1>Steps</h1>
            </div>
            <div data-aos='zoom-in' data-aos-duration='2000' className='container steps_container'>
                <div className='steps_wrapper'>
                    {
                        stepsData.map((step, index) => {
                            return <Step key={index} step={step} index={index} activeStep={activeStep} setCurrentStep={setCurrentStep} setActiveStep={setActiveStep}/>
                        })
                    }
                </div>
                <div className='step_description'>
                    <h3 className='step_title'>{currentStep.title}</h3>
                    <h4 className={`step_description${activeStep}`}>{currentStep.description}</h4>
                </div>
            </div>
        </section>
    )
}

export default WorkProcess