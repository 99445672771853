import React, {useEffect} from 'react'
import './blogOverviewPage.css'
import {blogData} from "../../Data"
import {Link} from "react-router-dom"
import {RxDoubleArrowRight} from "react-icons/rx"
import Aos from "aos"
import 'aos/dist/aos.css'

const BlogOverviewPage = () => {
    useEffect(() => {
        Aos.init({duration:1500})
    }, [])

    return (
        <>
            <section id='blog_overview_page'>
                <h2>All Articles</h2>
                <div className='container blog_overview_page_container'>
                    {
                        blogData.map((blog, index) => {
                            return (
                                <div data-aos='zoom-in' data-aos-duration='2000' key={index} className='overview_page_item'>
                                    <img className='blog_img' src={blog.img} alt='sketch'/>
                                    <div className="title"><h3>{blog.title}</h3></div>
                                    <div className="text">
                                        <span>{blog.description}</span>
                                        <Link to={blog.path} className='blog_link'>Read more&nbsp;
                                            <RxDoubleArrowRight className='icon'/>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </>
    )
}

export default BlogOverviewPage